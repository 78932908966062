import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Base from './app';
import BarMenu from './bar'
import Health from './health'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


ReactDOM.render(
  <Router>
    <Switch>
      <Route path='/health'>
        <Health />
      </Route>
      <Route path='/bar-*'>
        <BarMenu />
      </Route>
      <Route path='/'>
        <Base />
      </Route>
    </Switch>
  </Router>,
  document.getElementById('root')
);
