import React, { Component } from "react";
import Input from './input';
import Suggestion from './suggestion'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import './index.css'
import './common.css'

export default class Base extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      latitude: null,
      longitude: null,
      distance: 500,
      sessionKey: null,
      suggestion: null,
      outOfResults: false,
    }
  }

  onSliderChange = (value) => {
    this.setState({ distance: value });
  };

  fetchBackend = (param, e) => {
    fetch(process.env.REACT_APP_BACKEND_URL + 'suggest/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'lat': this.state.latitude,
        'long': this.state.longitude,
        'radius': this.state.distance,
        'address': this.state.address,
        'session_key': param === null ? this.state.sessionKey: null,
        'keyword': param,
      })
    }).then(res => res.json())
      .then(
        (result) => {
          this.setState({
            sessionKey: result.session_key, 
          })
          // if no result, this trumps all
          if (result.suggestion['donezo']) {
            this.setState({
              outOfResults: true,
            });
          } else if (this.state.suggestion !== null && 
            this.state.suggestion['place_id'] === result.suggestion['place_id']) {
              console.log('Got the same restaurant back, retrying')
              this.fetchBackend(null, null);
          } else {
            this.setState({
              isLoaded: true,
              suggestion: result.suggestion,
            })
          }
        },
        (error) => {
          alert('lol something went wrong');
          this.setState({
            error: error,
          })
        }
      );
  }

  clearSession = () => {
    this.setState({
      sessionKey: null,
      distance: 500,
      suggestion: null,
      outOfResults: false,
    })
  }

  updateLatLong = (latitude, longitude) => {
    this.setState({
      latitude: latitude,
      longitude: longitude,
    });
  }

  updateAddressSearch = (e) => {
    this.setState({
      address: e.target.value
    });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col xs={12} md={3}></Col>
          <Col xs={12} md={6}>
            <h1 className='centered'>Noo You Pick</h1>
            {this.state.outOfResults && (
              <Alert variant='danger'>
                <Alert.Heading>Sorry boss, got nothing more for ya...</Alert.Heading>
                <p>Let's try starting over again, maybe with a bigger radius this time</p>
              </Alert>
            )}
            <Input 
              onSliderChange={this.onSliderChange} 
              onButtonClick={this.fetchBackend}
              updateLatLong={this.updateLatLong}
              distance={this.state.distance}
              clearSession={this.clearSession}
              addressSearchBox={this.updateAddressSearch}
              outOfResults={this.state.outOfResults}
            />
            <Suggestion
              suggestion={this.state.suggestion}
              newSearchHandler={this.fetchBackend}
            />
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
      </Container>
    );
  }
}
