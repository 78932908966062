import React, { Component } from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import './common.css'
import './details.css'
import _ from 'lodash'

export default class RestaurantDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeId: this.props.placeId,
      isLoading: false,
      isLoaded: false,
      name: null,
      address: null,
      rating: null,
      ratingsCount: null,
      priceLevel: null,
      photos: [],
      googleUrl: null,
    }
  }

  fetchBackend = (e) => {
    this.setState({isLoading: true})
    fetch(process.env.REACT_APP_BACKEND_URL + 'details/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'place_id': this.state.placeId,
      })
    }).then(res => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: true,
            isLoading: false,
            name: data.name,
            address: data.formatted_address,
            googleRating: data.rating,
            googleRatingsCount: data.user_ratings_total,
            yelpRating: data.yelp_rating,
            yelpRatingsCount: data.yelp_reviewer_count,
            price: data.price,
            photos: data.photos,
            yelpPhotos: data.yelp_photos,
            googleUrl: data.url,
            yelpUrl: data.yelp_url,
            isOpen: data.is_open,
            phone: data.phone,
            categories: data.categories,
          });
          this.props.clickHandler();
        },
        (error) => {
          this.setState({
            error: error
          })
        }
      );
  }

  similarRestaurantWrapper = (category, e) => {
    this.setState({
      isLoading: true,
    })
    this.props.newSearchHandler(category, e)
  }

  render () {
    if (!this.state.isLoaded) {
      return (
        <Container>
          <Button 
            onClick={!this.state.isLoading ? this.fetchBackend : null}
            disabled={this.state.isLoading}
          >{this.state.isLoading ? <Spinner animation="border" size="sm"/>: "Get details"}</Button>
        </Container>
      )
    } else {
      return (
        <Container className='suggestion-text-div'>
          <Row>
            <h3 className='suggestion-text'>{this.state.name}</h3>
            <p className='suggestion-text'>{this.state.address}</p>
          </Row>
          <Row>
            <Col xs={2}>
              <strong className='suggestion-header'>Open?</strong>
            </Col>
            <Col xs={10} className='details-table-div'>
              <p className='suggestion-text'>{this.state.isOpen ? "Yeah, gogogo" : "Nope, it's closed :( maybe next time"}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <strong className='suggestion-header'>Price:</strong>
            </Col>
            <Col xs={10} className='details-table-div'>
              <p className='suggestion-text'>{this.state.price === null ? "No idea, sorry" : this.state.price}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={2} className='suggestion-header'>
              <strong>Google Rating:</strong>
            </Col>
            { this.state.googleRating ? (
              <Col xs={4} className='details-table-div'>
                <p className='suggestion-text'>{this.state.googleRating}/5 ({this.state.googleRatingsCount})</p>
              </Col>
            ) : (
              <Col xs={4} className='details-table-div'>
                <p className='suggestion-text'>None</p>
              </Col>
            )}
            <Col className='details-table-div'>
              {openInNewTabAnchor(this.state.googleUrl, 'Google')}
            </Col>
          </Row>
          <Row>
            <Col xs={2} className='suggestion-header'>
              <strong>Yelp Rating:</strong>
            </Col>
            { this.state.yelpRating ? (
              <Col xs={4} className='details-table-div'>
                <p className='suggestion-text'>{this.state.yelpRating}/5 ({this.state.yelpRatingsCount})</p>
              </Col>
            ) : (
              <Col xs={4} className='details-table-div'>
                <p className='suggestion-text'>None</p>
              </Col>
            )}
            <Col className='details-table-div'>
              {openInNewTabAnchor(this.state.yelpUrl, 'Yelp')}
            </Col>
          </Row>
          <Row>
            { _.map(this.state.yelpPhotos, (pUrl) => {
              return (
                <Col className='photo-div' key={pUrl}>
                  <img src={pUrl} alt="Restaurant Pic"/>
                </Col>
              )
            })}
          </Row>
          {this.state.categories && (
            <div>
              <Row>
                <Col>
                  <strong>Ok not this place but something similar </strong>
                </Col>
              </Row>
              <Row>
                { _.map(this.state.categories, (category) => {
                  return (
                    <Col xs={12 / this.state.categories.length} className='photo-div' key={category}>
                      <Button className="similar-button" variant='outline-dark' onClick={e => this.similarRestaurantWrapper(category, e)}>
                        {this.state.isLoading ? <Spinner animation="border" size="sm"/>: category}
                      </Button>
                    </Col>
                  )
                })}
              </Row>
            </div>
          )}
        </Container>
      )
    }
  }
}

function openInNewTabAnchor(url, openIn) {
  if (url)
    return (<a className='suggestion-text' target="_blank" rel="noreferrer" href={url}>Open in {openIn}</a>)
  else
    return ''
}
