import React, { Component } from "react";
import RestaurantDetails from './details'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import './common.css'
import './details.css'

export default class Suggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestion: null,
      showPreview: false,
      prevRestId: '',
      done: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.done) {
      return;
    }
    if (this.props.suggestion !== null) {
      if (this.props.suggestion['donezo']) {
        // out of new suggestions
        this.setState({
          suggestion: null,
          showPreview: false,
          done: true,
        })
        // TODO: add handler for out of places
        return;
      } else if (this.props.suggestion !== prevProps.suggestion) {
        this.setState({
          suggestion: this.props.suggestion,
          showPreview: true,
        })
      } 
    } else if (this.props.suggestion === null && this.state.suggestion !== null) {
      this.setState({
        suggestion: null
      })
    }
  }

  hideElements = () => {
    this.setState({
      showPreview: false,
    });
  }

  similarSearchWrapper = (e) => {
    this.setState({
      prevRestId: this.state.suggestion['place_id'],
    })
    this.props.newSearchHandler(e);
  }

  render() {
    if (this.state.suggestion !== null && !this.state.done) {
      return (
        <Container className='wrapper-div'>
          {this.state.showPreview && (
            <Row className='suggestion-text-div'>
              <h3 className='suggestion-text'>{this.state.suggestion['name']}</h3>
              {this.state.suggestion['rating'] && (
                <p className='suggestion-text'>Rating: {this.state.suggestion['rating']} ({this.state.suggestion['user_ratings_total']})</p>
              )}
              <p className='suggestion-text'>{this.state.suggestion['vicinity']}</p>
              <a className='suggestion-text' target="_blank" rel="noreferrer" href={'https://www.google.com/maps/place?q=place_id:' + this.state.suggestion['place_id']}>Open in Google maps</a>
            </Row>
          )}
          <Row>
            <RestaurantDetails
              placeId={this.state.suggestion['place_id']}
              clickHandler={this.hideElements}
              newSearchHandler={this.similarSearchWrapper}
              key={this.state.suggestion['place_id']}
            />
          </Row>
        </Container>
      )
    } else {
      return (<Container></Container>)
    }
  }
}
