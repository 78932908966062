import React, { Component } from "react";
import Slider from 'rc-slider';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {isMobile} from "react-device-detect"

import 'rc-slider/assets/index.css';
import './common.css'
import './input.css'

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: null,
      longitude: null,
      initInput: true,
      showGetAnother: true,
      locationErrorCode: null,
      clickedButton: false,
    }
    this.geoPermissionTimer = null;
  }

  componentDidMount() {
    if (this.getLatLong(false)) {
      clearInterval(this.geoPermissionTimer);
    } else {
      this.geoPermissionTimer = setInterval(
        () => this.getLatLong(), 500
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.geoPermissionTimer);
  }

  getLatLong = (byButton) => {
    if (this.state.latitude !== null) {
      return true;
    }
    if (isMobile && !byButton) {
      return false;
    }
    navigator.geolocation.getCurrentPosition(
    p => {
      console.log('got location')
      this.setState({
        latitude: p.coords.latitude,
        longitude: p.coords.longitude
      });
      this.props.updateLatLong(this.state.latitude, this.state.longitude);
      clearInterval(this.geoPermissionTimer);
      if (byButton) {
        this.buttonClickWrapper(null);
      }
      return true;
    },
    error => {
      this.setState({
        locationErrorCode: error.code
      })
      console.log('Failed to get geolocation: ' + error.code + ', ' + error.message)
      return false;
    });
  }

  buttonClickWrapper = (e) => {
    if (e === null) {
      this.props.onButtonClick(null, e);
      return;
    }
    if (this.state.initInput && isMobile) {
      this.getLatLong(true);
    }
    this.setState({
      clickedButton: true,
      initInput: false,
    });
    if (this.state.latitude === null) {
      console.log('No lat/long yet, not sending call')
      return;
    }
    this.props.onButtonClick(null, e)
  }

  resetButton = (e) => {
    this.setState({
      initInput: true,
      clickedButton: false,
    })
    this.props.clearSession();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.outOfResults !== this.props.outOfResults && 
      this.state.showGetAnother === this.props.outOfResults) {
      this.setState({
        showGetAnother: !this.props.outOfResults,
      })
    }
  }

  saveAddress = (e) => {
    this.setState({
      currentAddress: e.target.value,
    })
    this.props.addressSearchBox(e);
  }

  render() {
    if (this.state.initInput) {
      return (
        <Container>
          <Row>
            <Col>
              <p className='centered'>Can't figure out where to eat? I'll make the decision for you</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='centered'>How far are you willing to walk/drive?</p>
            </Col>
          </Row>
          <Row>
            <Col xs={10} className='distance-slider-col'>
              <Slider 
                min={100}
                max={5000} 
                step={100} 
                dots={false} 
                defaultValue={500}
                onChange={this.props.onSliderChange} 
                className='centered' />
            </Col>
            <Col xs={2} className='distance-number-col'>
                <div className='distance-number'>
                  {this.props.distance}m
                </div>
            </Col>
          </Row>
          <Row className='section-div'>
            <button 
              className="find-place-button" 
              onClick={this.buttonClickWrapper}>FIND ME A PLACE</button>
          </Row>
        </Container>
      )
    } else {
      return (
        <Container>
          {this.state.showGetAnother && (
            <Row className='section-div'>
              <button 
                  className="find-place-button" 
                  onClick={(e) => this.props.onButtonClick(null, e)}>FIND ME ANOTHER</button>
            </Row>
          )}
          <Row className='section-div'>
            <button 
                className="find-place-button" 
                onClick={this.resetButton}>OK RESTART</button>
          </Row>
        </Container>
      )
    }
  }
}
